<template>
  <div class="promotion">
    <GeneralDialog :visible.sync="visible" :visibleFooter="true" :needFullscreen="true" :hideChat="true">
      <div class="top">
        <div class="header-text">{{ $t(headerText) }}</div>
        <ol v-html="$t(content)"></ol>
      </div>

      <div class="tnc">
        <el-form :model="form" :rules="rule" :show-message="false" ref="Form">
          <el-form-item prop="checked">
            <el-checkbox v-model="form.checked" data-testid="tncConfirm">
              <i18n path="openAdditAcc.byTicking" tag="div" class="tncLabel">
                <template v-slot:tnc>{{ $t('openAdditAcc.tnc') }}</template>
              </i18n>
            </el-checkbox>
          </el-form-item>
        </el-form>
      </div>

      <div class="form-box">
        <div class="btn-box">
          <el-button class="btn-blue" @click="agreeAction" :disabled="!form.checked" data-testid="agreeAction">
            {{ $t('common.button.confirm') }}
          </el-button>
        </div>
      </div>
    </GeneralDialog>
  </div>
</template>

<script>
import GeneralDialog from '@/components/dialog/general/Dialog.vue';
import { checkAgreedTNC } from '@/util/validation';

export default {
  props: {
    agreeTncVisible: Boolean,
    headerText: String,
    content: String
  },
  components: { GeneralDialog },
  data() {
    return {
      visible: false,
      form: {
        checked: false
      },
      rule: {
        checked: [{ validator: checkAgreedTNC, trigger: 'change' }]
      }
    };
  },
  watch: {
    agreeTncVisible(bool) {
      this.form.checked = false;
      this.visible = bool;
    },
    visible(bool) {
      this.disableAgreeTncVisible(bool);
    }
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    }
  },
  methods: {
    disableAgreeTncVisible(bool) {
      this.$nextTick(() => {
        this.$emit('update:agreeTncVisible', bool);
      });
    },
    agreeAction() {
      this.$emit('isAgreeTnc', true);
      this.disableAgreeTncVisible(false);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/promotion/agreeTnc.scss';
</style>
