<template>
  <div class="cb-dialog">
    <ConditionDialog :visible.sync="open" :isSuccess="successCallbackType" @closCbDialog="handleClose(false)">
      <template #content>
        <i18n
          :path="
            successCallbackType ? `promotion.${campaignName}.optInDialog` : `promotion.${campaignName}.optOutDialog`
          "
          tag="p"
        >
          <template v-slot:br>
            <br />
          </template>
        </i18n>
      </template>
    </ConditionDialog>
  </div>
</template>

<script>
import ConditionDialog from '@/components/dialog/ConditionDialog.vue';

export default {
  name: 'CallbackDialog',
  components: {
    ConditionDialog
  },
  props: {
    show: Boolean,
    successCallbackType: Boolean,
    campaignName: String
  },
  data() {
    return {
      open: false
    };
  },
  watch: {
    show(val) {
      this.open = val;
    },
    open(bool) {
      this.handleClose(bool);
    }
  },
  methods: {
    handleClose(bool) {
      this.$nextTick(() => {
        this.$emit('update:show', bool);
      });
    }
  }
};
</script>
