<template>
  <div id="depositBonus" :class="campaignName">
    <div class="banner-container">
      <div v-if="campaignName === 'depositBonus' && banner" class="new-banner-image" :class="banner.bgClass">
        <BannerItemV2 v-bind="banner" :lang="imgLang" />
      </div>
      <div
        v-else-if="campaignName === 'deposit20Bonus' || campaignName === 'depositMY20Bonus'"
        class="new-banner-image"
        :style="{
          backgroundImage: 'url(' + bannerLink() + ')',
          backgroundSize: 'cover'
        }"
      >
        <BannerItemV1 v-bind="banner" :lang="imgLang" />
      </div>
    </div>
    <div class="content-box">
      <div class="inner">
        <FundAccount
          :optIn="optIn"
          :campaignName="campaignName"
          :campaignID="campaignID"
          @successfulCallback="successfulCallback"
        ></FundAccount>
        <div class="over-bonus-alert" v-if="overBonus">
          {{ $t(`promotion.${campaignName}.overBonus`, { totalLimit: totalLimit }) }}
        </div>
        <div class="table-box">
          <div class="table-title">
            {{ $t(`promotion.${campaignName}.bonusHistory`) }}
          </div>
          <ul class="table-demo">
            <li class="table-item" :class="{ active: activeName === 'creditReceived' }">
              <component
                ref="CredictReceived"
                :is="campaignTypeList[campaignName].component"
                :tableData="tableData"
                :welcomeRateLimit="welcomeRateLimit"
                :campaignName="campaignName"
              ></component>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BannerItemV1 from '@/components/banner/item/v1/BannerItem.vue';
import BannerItemV2 from '@/components/banner/item/v2/BannerItem.vue';
import { banners } from '@/constants/banners';
import FundAccount from '@/components/promotion/depositBonus/FundAccount';
import CredictReceived from '@/components/promotion/depositBonus/CredictReceived';
import Credict20Received from '@/components/promotion/deposit20Bonus/CredictReceived';
import { apiCampaignInfo } from '@/resource';

export default {
  name: 'depositBonus',
  components: { FundAccount, CredictReceived, Credict20Received, BannerItemV1, BannerItemV2 },
  data() {
    return {
      activeName: 'creditReceived',
      overBonus: false,
      tableData: [],
      optIn: true,
      totalLimit: 10000,
      welcomeRateLimit: 0,
      campaignTypeList: {
        depositBonus: { component: 'CredictReceived' },
        deposit20Bonus: { component: 'Credict20Received' },
        depositMY20Bonus: { component: 'Credict20Received' }
      },
      banner: null
    };
  },
  computed: {
    campaignID() {
      let campaign = this.$store.state.promotion.eligibleCampaigns.find(c => c.campaignType === 'DEPOSIT_BONUS_2');
      return campaign ? campaign.id : null;
    },
    imgLang() {
      return this.$store.state.common.lang;
    },
    campaignName() {
      return this.$route.name;
    }
  },
  methods: {
    getDespositBonusInfo() {
      apiCampaignInfo(this.campaignID)
        .then(resp => {
          if (resp.data.code === 0) {
            this.tableData = resp.data.data.participants;
            this.optIn = resp.data.data.in;
            this.overBonus = resp.data.data.reachedMaximumBonus;
            this.totalLimit = resp.data.data.creditLimit;
            this.welcomeRateLimit = resp.data.data.welcomeRateLimit;
          }
        })
        .catch(e => {
          console.log(e);
        });
    },
    successfulCallback() {
      this.getDespositBonusInfo();
    },
    bannerLink() {
      return require(`@/assets/images/banner/deposit20Bonus/en_US.png`);
    },
    getBanner() {
      this.banner =
        this.campaignID === 32 || this.campaignID === 43
          ? this.$config
              .banner(this.regulator, this.GLOBAL_DOMAIN_WEBSITE)
              .filter(el => el.promotionId === this.campaignID)[0]
          : banners.filter(el => el.promotionId === this.campaignID)[0];
    }
  },
  mounted() {
    this.getDespositBonusInfo();
    this.getBanner();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/depositBonus.scss';
</style>
